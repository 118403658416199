//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages

import { createContext } from 'react';

const FacetsContext = createContext({
  multiSelect: false,
  hasFacets: false,
  selectedFacets: [],
  availableFacets: [],
  urlWithFacet: null,
  urlWithoutFacet: null,
  urlWithoutFacets: null,
});

export default FacetsContext;