//[156332] [Siremballage] 3.10. Custom menu - [F1]

import styles from '../MainNav.module.scss';
import { memo, useContext, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import CustomNavItemSubItems from '../CustomNavItemSubItems';
import NavItemSubItems from '../NavItemSubItems';
import { MainNavItemPropTypes } from '../PropTypes';
import { shouldRenderNavigation } from '../../helpers';
import { useNavItemEventHandlersData } from '../hooks';
//import { AccordionStateContext } from './AccordionStateProvider';

const CustomNavItem = ({
  item: {
    id,
    cssClass,
    title,
    subtext,
    openIcon,
    closeIcon,
    link,
    children: subItems,
    image,
  },
  isActive,
  ariaSubmenuText,
  skipSubItemsRender = false,
}) => {
  const shouldRenderSubItems = !skipSubItemsRender && shouldRenderNavigation(subItems);
  const { customNavItemEventHandlers, navItemLinkEventHandlers } = useNavItemEventHandlersData(shouldRenderSubItems);

  const navItemRootClassNames = `${styles.navItemRoot} ${isActive ? styles.active : ''} ${cssClass || ''}`;
  const hypContainerStyles = `${styles.hypContainer} ${shouldRenderSubItems ? styles.hasSubItems : ''}`;

  const [isVisible, setVisible] = useState(false);
  //const [activeItemId, setActiveItemId] = useContext(AccordionStateContext);
  const linkRef = useRef(null);
  let handleClick = null, handleKeyDown = null, onSubItemsVisible = null;

  if (shouldRenderSubItems) {
    handleClick = e => {
      setVisible(!isVisible);
      //setActiveItemId(id);
      //linkRef.current.focus();
    };
  }

  return (
    <li
      className={navItemRootClassNames}
      {...customNavItemEventHandlers}
      aria-keyshortcuts={shouldRenderSubItems ? 'ArrowRight ArrowLeft' : null}
      data-should-handle-aria={shouldRenderSubItems ? 'true' : null}
      aria-controls={shouldRenderSubItems ? id : null}
    >
      {/* Element should be focusable for proper event handling in Mobile Safari, Legacy MS Edge and IE11 when using laptop with touch screen. */}
      <div className={hypContainerStyles} tabIndex="-1" onClick={handleClick} >
        {!shouldRenderSubItems &&
          <Link
            {...link}
            className={styles.hypTopLvl}
            {...navItemLinkEventHandlers}
            aria-haspopup={shouldRenderSubItems ? 'true' : null}
          >
            <div className={`${styles.iconItem}`}>
              <div>
                {openIcon && <img src={openIcon} alt={''} className={styles.iconItemIcon} />}
              </div>
              <div className={styles.iconItemLines}>
                <span>{title}</span>
                <span className={styles.iconItemLinesSubtext}>{subtext}</span>
              </div>
            </div>
          </Link>
        }
        {shouldRenderSubItems &&
            <div className={`${styles.hypTopLvl} ${styles.iconItem}`}>
              <div>
                { openIcon && <img src={openIcon} alt={''} className={styles.iconItemIcon + ' custom-menu-open-icon'}   />}
                { closeIcon && <img src={closeIcon} alt={''} className={styles.iconItemIcon + ' custom-menu-close-icon'} />}
              </div>
              <div className={styles.iconItemLines}>
                <span>{title}</span>
                <span className={styles.iconItemLinesSubtext}>{subtext}</span>
              </div>
            </div>
        }
      </div>
      {shouldRenderSubItems &&
        <CustomNavItemSubItems
          subItems={subItems}
          image={image}
          title={title}
          id={id}
          expanded={isVisible}
          isDesktop
          ariaSubmenuText={ariaSubmenuText}
        />
      }
    </li>
  );
};

CustomNavItem.propTypes = {
  item: MainNavItemPropTypes,
  isActive: PropTypes.bool,
  ariaSubmenuText: PropTypes.string,
  skipSubItemsRender: PropTypes.bool,
};

export default memo(CustomNavItem);
