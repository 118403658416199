import PropTypes from 'prop-types';
import { useState } from 'react';
import { Placeholder } from 'components/primitives/placeholders';
import { useCanViewUom } from 'components/primitives/product';
import { SimpleText } from 'components/sanaText';
import QuantityBox from './QuantityBox';
import { RequestsQuote } from 'components/primitives/requestsQuote';
import { AddToWishlistButton } from 'components/objects/productGroup/wishlist';
import { useIsVisualDesigner } from 'utils/hooks';

//TICKET 156337 - 3.16. Product which are Available on demand
const OrderBox = ({
  product,
  className = '',
  canOrderProducts,
}) => {
  const {
    isOrderable,
    isAvailableOnDemand,
    uoms
  } = product;

  const isDesignerMode = useIsVisualDesigner();

  //[215470] A partir de illisible
  const canViewUom = useCanViewUom();
  const [uomId] = useState(product.uom && product.uom.id);
  const showUomSelector = canViewUom && uomId && uoms && uoms.length > 0;

  let content = null;
  const placeholder = <Placeholder className="placeholder" />;

  if (isOrderable == null) {
    content = placeholder;
  } else if (isOrderable === false) {
    content = (
      <>
        <span className={showUomSelector ? "msg-not-available-uom" : "msg-not-available"}>
          <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
        </span>
        <QuantityBox canOrderProducts={canOrderProducts} product={product} />
      </>
    );
  } else {
    content = <QuantityBox canOrderProducts={canOrderProducts} product={product} />;
  }

  //TICKET 156337 - 3.16. Product which are Available on demand
  if ((isAvailableOnDemand && !isOrderable)) {
    return (<div className={className}>
      <div className="ProductGroup_quantity-box ProductGroup_available-on-demand">
        <RequestsQuote product={product} alignmentClassName={''} placeholder={<Placeholder />} />
        <AddToWishlistButton product={product} isDesignerMode={isDesignerMode} />
      </div>
    </div>);
  }

  return (
    <div className={className}>
      {content}
    </div>
  );
};

OrderBox.propTypes = {
  product: PropTypes.shape({
    isOrderable: PropTypes.bool,
  }),
  className: PropTypes.string,
  canOrderProducts: PropTypes.bool.isRequired,
};

export default OrderBox;
