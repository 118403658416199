import type { Middleware } from '../types';
import { redirectTo } from '../actions';
import { RouteName } from 'routes';

const ignoreRoutes = new Set([
  RouteName.Home,
  RouteName.NotFound,
  RouteName.WebsiteRedirect,
]);

const trailingRoutes = new Set([
  RouteName.ProductList,
  RouteName.ProductGroupPage, //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
  RouteName.MyAccount,
  RouteName.SubAccounts,
  RouteName.Orders,
  RouteName.Quotes,
  RouteName.Invoices,
  RouteName.ReturnOrders,
  RouteName.CreditNotes,
  RouteName.ReturnReceipts,
  RouteName.Shipments,
  RouteName.SalesAgreements,
  RouteName.NewsOverview,
]);

const trailingMiddleware: Middleware = (route, location) => {
  if (ignoreRoutes.has(route.routeName))
    return;

  const trailingRoute = trailingRoutes.has(route.routeName);
  const trailingPath = location.pathname.endsWith('/');

  if (trailingRoute && trailingPath)
    return;

  if (!trailingRoute && !trailingPath)
    return;

  const pathname = trailingRoute
    ? location.pathname + '/'
    : location.pathname
      .substring(0, location.pathname.length - 1);

  const routeData = { routeName: route.routeName, params: route.params };
  return redirectTo(pathname + location.search, 301, routeData);
};

export default trailingMiddleware;
