//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
//[156335] [F1] [Siremballage] 3.14. Level 3 product group pages

import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIsMobileViewport } from 'utils/hooks';
import { makeRichText } from 'utils/render';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import Stock from '../Stock';
import ProductContext from '../ProductContext';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { ProductPrice, ProductPriceForGroupPages } from 'components/primitives/product';
import OrderBox from '../OrderBox';
import AddToBasketButton from '../AddToBasketButton';
import { WithProductClickTracking } from 'components/objects/analytics';
import { EventSource } from 'behavior/analytics';
import { Placeholder } from 'components/primitives/placeholders';
import useEditableSpecifications from '../../product/useEditableSpecifications';
import { getSpecificationHeaders } from '../util';
import { SimpleText } from 'components/sanaText';
import styles from '../ProductGroup.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import { Table } from 'components/primitives/table';
import { Facets, FacetsProvider, facetUrlBuilder } from '../facets';
import Paging from './Paging';
import { useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';


const AvailableItems = ({ products: productsInput, facets, totalCount, attributes, groupId, containerId}) => {
  const [canViewStock, showPrice, canOrderProducts] = useHasAbilities(AbilityTo.ViewStock, AbilityTo.ViewPrices, AbilityTo.OrderProducts);
  const tableHeadingPlaceholder = <Placeholder className={styles.tableHeadingPlaceholder} />;
  const isMobile = useIsMobileViewport();
  const specifications = attributes;
  const specificationHeaders = getSpecificationHeaders(specifications || [], productsInput || []);

  const isWebclient = useSelector(state => state.user.isWebClient); //191777: tarif client
  const showListPrice = !isWebclient ? true : false;

  const products = productsInput;

  //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
  const qtyList = [];
  if (products)
    products.forEach(x => {
      if (x.volumePrices)
        x.volumePrices.forEach(y => qtyList.push(y.quantity));
    
    });
  const volumeHeaders = [...new Set(qtyList)].sort(function (a, b) { return a - b; }).slice(0, 5)
  const showVolumes = volumeHeaders && volumeHeaders.length > 0;

  const documentWidthRef = useRef(0);
  const [onScroll, setOnScroll] = useState(false);
  const tabId = containerId === 'normal' ? 'availableitems' : 'specialFabrication';

  const setScrolling = () => {
    if (document.querySelector(`#${tabId} .ProductGroup_variants-table`) !== null && document.querySelector(`#${tabId} .ProductGroup_variants-table thead`) !== null) {
      setOnScroll(document.querySelector(`#${tabId} .ProductGroup_variants-table`).clientWidth !== document.querySelector(`#${tabId} .ProductGroup_variants-table thead`).clientWidth);
    }
  };

  const onRendered = useMemo(() => {
    return throttle(() => {
      setTimeout(() => {
        setScrolling();
      }, 0);
    }, 200, { leading: false, trailing: true });
  }, [specificationHeaders]);

  const resizeHandler = () => {
    const documentWidth = document.documentElement.clientWidth;
    if (documentWidth !== documentWidthRef.current) {
      // Horizontal resize
      setScrolling();
    }

    documentWidthRef.current = documentWidth;
  };

  useEffect(() => {

    window.addEventListener('resize', resizeHandler);
    containerId === 'normal' ? document.querySelector("#pgp_tab_availableitems").addEventListener('click', onRendered) : document.querySelector("#pgp_tab_specialFabrication").addEventListener('click', onRendered);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      containerId === 'normal' ? document.querySelector("#pgp_tab_availableitems").removeEventListener('click', onRendered) : document.querySelector("#pgp_tab_specialFabrication").removeEventListener('click', onRendered);
      onRendered.cancel();
    };
  }, []);

  useEffect(() => void onRendered(), [products]);

  return (
    <>
      <div>
        <FacetsProvider facets={facets} urlBuilder={facetUrlBuilder}>
          <Facets key={groupId} />
        </FacetsProvider>
      </div>
      <div className={`${styles.container}`}>
     
        <Table className={`${tableStyles.table} ${styles.variantsTable}`}>
          <thead>
            <tr>
              <th className={`${styles.stickyLeft} ${onScroll ? styles.stickyLeftShadowBar : ''}`} id="headerId">
                <SimpleText textKey="ProductGrouping_VariantsTab_IdHeader" placeholder={tableHeadingPlaceholder} />
              </th>
              {specificationHeaders.map(({ key, name }) => (
                <th key={key} id={key} className={`${specificationHeaders[specificationHeaders.length - 1].key === key ? styles.fullWidth : ''} ${styles.specificationHeader} ${specificationHeaders[0].key === key ? styles.leftAlign : ''}`} >
                  {name}
                </th>
              ))}
              {(!specificationHeaders || specificationHeaders.length <= 0) && <th className={styles.fullWidth}> </th>}
              {showPrice && showVolumes && isWebclient && //191777: tarif client
                //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
                <th id="headerVolumePrice" className={`${!isMobile ? 'desktop-volume-table-header' : 'mobile-volume-table-header'} ${onScroll ? styles.stickyRightShadowBar : ''} ${canOrderProducts && canViewStock && showListPrice ? styles.stickyRightVolPrice : (!canOrderProducts && !canViewStock && !showListPrice ? styles.stickyRightVolPriceEnd : (!canOrderProducts && canViewStock && showListPrice ? styles.stickyRightVolPriceNoOrder : (!canViewStock && canOrderProducts && showListPrice ? styles.stickyRightVolPriceNoStock : (!showListPrice && canOrderProducts && canViewStock ? styles.stickyRightVolPriceNoListPrice : (!showListPrice && !canOrderProducts ? styles.stickyRightVolPriceNoListPriceAndNoOrder : styles.stickyRightVolPriceNoListPriceAndStock)))))}`}>
                  <div className={styles.volumePriceCellHeader}>
                    <div className={styles.textLeftMobile}><SimpleText textKey="ProductGrouping_VolumePrice" placeholder={tableHeadingPlaceholder} /></div>
                    <div className={!isMobile ? 'desktop-volume-table' : ' visually-hidden'}>
                      <div className={styles.volumePriceCell}>
                        {volumeHeaders.map(header => <div key={header} className={styles.volumeCell}><SimpleText textKey="ProductGrouping_VolumePrice_HeaderTemplate" formatWith={[header]} placeholder={tableHeadingPlaceholder} /></div>)}
                      </div>
                    </div>
                  </div>
                </th>
              }
              {showPrice && !isWebclient && //191777: tarif client
                <th id="headerPrice" className={`${onScroll && !showVolumes ? styles.stickyRightShadowBar : ''} ${styles.hideMobile} ${styles.priceCell} ${styles.price} ${canOrderProducts && canViewStock ? styles.stickyRightPrice : (!canOrderProducts && !canViewStock ? styles.stickyRightPriceEnd : (!canOrderProducts ? styles.stickyRightPriceNoOrder : styles.stickyRightPriceNoStock))}`}>
                  <SimpleText textKey="Price" placeholder={tableHeadingPlaceholder} />
                </th>
              }
              {canViewStock &&
                <th id="headerStockIndication" className={`${onScroll && !showPrice ? styles.stickyRightShadowBar : ''} ${styles.stock} ${canOrderProducts ? styles.stickyRightStock : styles.stickyRightStockEnd}`}>
                  <SimpleText textKey="ProductGrouping_VariantsTab_AvailabilityHeader" placeholder={tableHeadingPlaceholder} />
                </th>
              }
              {canOrderProducts &&
                <th className={`${onScroll && !showPrice && !canViewStock ? styles.stickyRightShadowBar : ''} ${styles.orderHeader} ${styles.stickyRightQty} ${styles.order}`} id="headerQuantity">
                  <SimpleText textKey="ProductGrouping_VariantsTab_QuantityHeader" placeholder={tableHeadingPlaceholder} />
                </th>
              }
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.id}>
                <td className={`${styles.stickyLeft} ${onScroll ? styles.stickyLeftShadowBar : ''}`} >
                  <WithProductClickTracking product={product} trackingSource={EventSource.ProductGroup}>
                    {handleClick => (
                      <Link onClick={handleClick} url={product.url} to={routesBuilder.forProduct(product.id)} className={styles.productId} title={product.title}>
                        {product.id}
                      </Link>
                    )}
                  </WithProductClickTracking>
                </td>
                {specificationHeaders.map(({ key }, index ) => {
                  const specification = product.specifications && product.specifications.find(s => s.key === key); //199365_Modification_Centrage_Colonne

                  return (
                    <td key={key} className={`${styles.specificationRow} ${index === 0 ? styles.leftAlign: ''}`} >
                      <span>
                        {specification ? makeRichText(specification.value) : <SimpleText textKey="ProductCompare_EmptySpecification" />}
                      </span>
                    </td>
                  );
                })}
                {(!specificationHeaders || specificationHeaders.length <= 0) && <td className={styles.fullWidth}> </td>}
                {showPrice && showVolumes && isWebclient &&
                  //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
                  <td className={`${!isMobile ? 'desktop-volume-table-block' : 'mobile-volume-table-block'} ${onScroll ? styles.stickyRightShadowBar : ''} ${styles.productAction} ${canOrderProducts && canViewStock && showListPrice ? styles.stickyRightVolPrice : (!canOrderProducts && !canViewStock && !showListPrice ? styles.stickyRightVolPriceEnd : (!canOrderProducts && canViewStock && showListPrice ? styles.stickyRightVolPriceNoOrder : (!canViewStock && canOrderProducts && showListPrice ? styles.stickyRightVolPriceNoStock : (!showListPrice && canOrderProducts && canViewStock ? styles.stickyRightVolPriceNoListPrice : (!showListPrice && !canOrderProducts ? styles.stickyRightVolPriceNoListPriceAndNoOrder : styles.stickyRightVolPriceNoListPriceAndStock)))))}`}>
                    <div className={!isMobile ? 'desktop-volume-table' : ' visually-hidden'}>
                      <div className={styles.volumePriceCell}>
                                {volumeHeaders.map(header => <div key={header} className={styles.volumeCell}>{product.volumePrices && product.volumePrices.find(x => x.quantity === header) ? (product.isAvailableOnDemand && !product.isOrderable ? (<><SimpleText textKey="ProductGroup_EmptyPrice" /></>) : <ProductPrice salesPrice={product.volumePrices.find(x => x.quantity === header).price} basePrice={product.volumePrices.find(x => x.quantity === header).price} />) : (<><SimpleText textKey="ProductGroup_EmptyPrice" /></>)}</div>)}
                      </div>
                    </div>
                    <div className={!isMobile ? 'visually-hidden' : 'mobile-volume-table'}>
                      <div className={styles.volumePriceCell}>
                        <div className="volume-price-header">
                          {volumeHeaders.map(header => <div key={header} className={styles.volumeCell}><SimpleText textKey="ProductGrouping_VolumePrice_HeaderTemplate" formatWith={[header]} placeholder={tableHeadingPlaceholder} /></div>)}
                        </div>
                        <div className="volume-price-data">
                                    {volumeHeaders.map(header => <div key={header} className={styles.volumeCell}>{product.volumePrices && product.volumePrices.find(x => x.quantity === header) ? (product.isAvailableOnDemand && !product.isOrderable ? (<><SimpleText textKey="ProductGroup_EmptyPrice" /></>) : <ProductPrice salesPrice={product.volumePrices.find(x => x.quantity === header).price} basePrice={product.volumePrices.find(x => x.quantity === header).price} />) : (<><SimpleText textKey="ProductGroup_EmptyPrice" /></>)}</div>)}
                        </div>
                      </div>
                    </div>
                  </td>
                }
                {showPrice && !isWebclient &&
                  <td className={`${onScroll && !showVolumes ? styles.stickyRightShadowBar : ''} ${styles.productAction} ${styles.priceCell} ${styles.price} ${canOrderProducts && canViewStock ? styles.stickyRightPrice : (!canOrderProducts && !canViewStock ? styles.stickyRightPriceEnd : (!canOrderProducts ? styles.stickyRightPriceNoOrder : styles.stickyRightPriceNoStock) )}`}>
                    <div>
                      {/*TICKET 156337 - 3.16. Product which are Available on demand*/}
                            {product.isAvailableOnDemand && !product.isOrderable ? (<><SimpleText textKey="ProductGroup_EmptyPrice" /></>) : (<ProductPriceForGroupPages salesPrice={product.price} basePrice={product.listPrice} />)}
                    </div>
                  </td>
                }
                {canViewStock &&
                  <td className={`${onScroll && !showPrice ? styles.stickyRightShadowBar : ''} ${styles.stock} ${canOrderProducts ? styles.stickyRightStock : styles.stickyRightStockEnd}`}>
                    <ProductContext.Provider value={product}>
                      <Stock />
                    </ProductContext.Provider>
                  </td>
                }
                {canOrderProducts &&
                  <td className={`${onScroll && !showPrice && !canViewStock ? styles.stickyRightShadowBar : ''} ${styles.orderBoxCell} ${styles.stickyRightQty} ${styles.order}`}>
                    <ProductContext.Provider value={product}>
                      <OrderBox
                        product={product}
                        className={styles.orderBox}
                        canOrderProducts={canOrderProducts}
                      />
                    </ProductContext.Provider>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </Table>

        {/*[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages*/}
        <div className={styles.footer}>
          <Paging totalCount={totalCount} loadedCount={products.length} containerId={containerId} />
        </div>
        </div>
    </>
  );
};

AvailableItems.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    price: PropTypes.number,
    listPrice: PropTypes.number,
    specifications: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
  attributes: PropTypes.array,
  groupId: PropTypes.string,
  containerId: PropTypes.string.isRequired,
};

export default memo(AvailableItems);
