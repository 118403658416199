//[156335] [F1] [Siremballage] 3.14. Level 3 product group pages

import styles from '../../ProductGroup.module.scss';
import { SimpleText } from 'components/sanaText';
export function nonDownloadable(attachment) {
  return (
    <>
      {attachment.title}
      <span className={styles.unableLoadTitle}>
        <SimpleText textKey="UnableLoadAttachmentInOfflineMode" />
      </span>
    </>
  );
}
