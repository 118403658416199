//[156335] [F1] [Siremballage] 3.14. Level 3 product group pages

import { memo } from 'react';
import { Col, Row } from 'components/primitives/grid';
import PropTypes from 'prop-types';
import AvailableItems from './tabs/AvailableItems';
import { Tabs } from 'components/objects/tabs';
import { SimpleText } from 'components/sanaText';
import { renderHTML } from 'utils/render';
import Attachments from './tabs/attachments';

const ProductGroupTabs = ({ products: productsInput, facets, totalCount, totalCountInit, productsSf: productsSfInput, facetsSf, totalCountSf, totalCountSfInit, description, attachments, attributes, groupId }) => { //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages

  const showDescription = description && !!description.length;
  const showAttachments = attachments && !!attachments.length;

  return (
    <Row className="print-no-flex">
      <Col xs={12} lg={12} xl={12}>
        <Tabs name="pgp" resetDependency={groupId}>
          {totalCountInit > 0 &&
            <Tabs.Item
              tabKey="availableitems"
              itemCountInit={totalCountInit}
              itemCount={totalCount}
              title={<SimpleText textKey="ProductGroup_AvailableItemsTabTitle" formatWith={[totalCountInit]} />}
            >
              <AvailableItems containerId={'normal'} products={productsInput} facets={facets} totalCount={totalCount} attributes={attributes} groupId={groupId} />
            </Tabs.Item>
          }
          {totalCountSfInit > 0 &&
            <Tabs.Item
              tabKey="specialFabrication"
              itemCountInit={totalCountSfInit}
              itemCount={totalCountSf}
              title={<SimpleText textKey="ProductGroup_SpecialFabricationTabTitle" formatWith={[totalCountSfInit]} />}
            >
              <AvailableItems containerId={'sf'} products={productsSfInput} facets={facetsSf} totalCount={totalCountSf} attributes={attributes} groupId={groupId} />
            </Tabs.Item>
          }
          {showDescription && (
            <Tabs.Item
              tabKey="description"
              itemCountInit={0}
              itemCount={0}
              title={<SimpleText textKey="ProductGroup_FullDescriptionTabTitle" />}
            >
              <div className="description fr-view">{renderHTML(description)}</div>
            </Tabs.Item>
          )}
          {showAttachments && (
            <Tabs.Item
              tabKey="attachments"
              itemCountInit={0}
              itemCount={0}
              title={<SimpleText textKey="ProductGroup_AttachmentsTabTitle" />}
            >
              <Attachments attachments={attachments} />
            </Tabs.Item>
          )}
        </Tabs>
        
      </Col>
    </Row>
  );
};

ProductGroupTabs.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    price: PropTypes.number,
    listPrice: PropTypes.number,
    specifications: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
  facets: PropTypes.object,
  totalCount: PropTypes.number,
  totalCountInit: PropTypes.number,
  productsSf: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    price: PropTypes.number,
    listPrice: PropTypes.number,
    specifications: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
  facetsSf: PropTypes.object,
  totalCountSf: PropTypes.number,
  totalCountSfInit: PropTypes.number,
  description: PropTypes.string,
  attachments: PropTypes.array,
  attributes: PropTypes.array,
  groupId: PropTypes.string,
};

export default memo(ProductGroupTabs);
