import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import type { StateObservable } from 'redux-observable';
import type { ProductGroupPage, ProductGroupPageResponse } from './types';

import { of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { productGroupPageQuery } from './queries';
import { areAnalycticsSettingsLoaded } from 'behavior/analytics';
import { requestCalculatedFields } from './actions';
import { ProductMediaType } from '../product';
import { parseVideoData, VideoProviderType } from 'utils/video';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { RowContentElement, RowContentElementData, parseContent } from 'behavior/content';

type Params = {
  params: {
    id: string;
    language: number;

    //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
    facets?: Record<string, string[]>;
    page?: number;
    count?: number;
  };
  options?: Options, //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
};

//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
type Options = {
  page?: number;
  size?: number;
  productsOnly: boolean; //[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages
  appendProducts: boolean; //[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages
};

//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
type FacetFilter = {
  name: string;
  values: string[];
};

//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
type Facets = {
  filter: FacetFilter[];
};

export default ({ params: { id, language, facets, page, count }, options }: Params, state$: StateObservable<AppState>, { api }: StoreDependencies) => state$.pipe(
  first(areAnalycticsSettingsLoaded),
  switchMap(({ settings, analytics }) => {
    if (!settings.product.productGrouping.isEnabled)
      return of(null);

    const loadCategories = analytics.isTrackingEnabled;
    const loadOptions = createLoadOptions(facets, page, count, options, settings.productList.listProductAmount); //[156335] [F1] [Siremballage] 3.14. Level 3 product group pages
    const loadOptionsEmpty = createLoadOptions(undefined, undefined, undefined, undefined, settings.productList.listProductAmount); //[156335] [TABS] [Siremballage] 3.14. Level 3 product group pages

      return api.graphApi<ProductGroupPageResponse>(productGroupPageQuery, { id: id, options: loadOptions, emptyOptions: loadOptionsEmpty, loadCategories }).pipe(
      map(({ pages: { productGroupDetails } }) => {
        if (!productGroupDetails)
          return null;

        const component = PageComponentNames.ProductGroup;
        const productGroup = productGroupDetails.productGroup;
        const groupPage = state$.value.page as ProductGroupPage; //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
        const page: ProductGroupPage = {
          ...productGroup,
          media: processMedia(productGroup.title, productGroup.media),
          headerContent: productGroup.headerContent && parseContent(productGroup.headerContent), //[156335] [F1] [Siremballage] 3.14. Level 3 product group pages
          footerContent: productGroup.footerContent && parseContent(productGroup.footerContent), //[156335] [F1] [Siremballage] 3.14. Level 3 product group pages
          component,
          products: options?.appendProducts ? [...groupPage.products, ...productGroup.groupProducts.products] : productGroup.groupProducts.products, //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages //[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages
          facets: productGroup.groupProducts.facets, //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
          totalCount: productGroup.groupProducts.totalCount,//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
          totalCountInit: productGroup.groupProductsInit.totalCount, //[156335] [TABS] [Siremballage] 3.14. Level 3 product group pages
          productsSf: options?.appendProducts ? [...groupPage.productsSf, ...productGroup.groupProductsSpecialFabrication.products] : productGroup.groupProductsSpecialFabrication.products, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
          facetsSf: productGroup.groupProductsSpecialFabrication.facets, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
          totalCountSf: productGroup.groupProductsSpecialFabrication.totalCount, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
          totalCountSfInit: productGroup.groupProductsSpecialFabricationInit.totalCount, //[156335] [TABS] [Siremballage] 3.14. Level 3 product group pages
          backTo: getBackTo(state$, [
            RouteName.ProductGroupPage,
          ], language),
        };

        if (productGroup.products.length === 0)
          return { page };

        const productsCountPerBatch = settings.productList.listProductAmount * 2;
        const idsNormal = options?.appendProducts ? productGroup.groupProducts.products.map(p => p.id) : productGroup.groupProducts.products.map(p => p.id); //[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages
        const idsSf = options?.appendProducts ? productGroup.groupProductsSpecialFabrication.products.map(p => p.id) : productGroup.groupProductsSpecialFabrication.products.map(p => p.id); //[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages
        const ids = [...idsNormal, ...idsSf]; //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages

        return { page, action$: of(requestCalculatedFields(id, {ids , productsCountPerBatch })) };
      }),
    );
  }),
);

const processMedia = (pageTitle: string, media?: Media): ProcessedMedia | undefined => {
  if (!media)
    return undefined;

  const results: ProcessedMedia = [];

  media.forEach(item => {
    switch (item.type) {
      case ProductMediaType.Video:
        const videoData = parseVideoData(item.url);

        if (!videoData)
          break;

        results.push({
          type: item.type,
          videoData,
          title: pageTitle,
        });
        break;

      case ProductMediaType.Image:
        if (item.small || item.medium || item.large) {
          if (item.title) {
            results.push(item as ProcessedImage);
          } else {
            results.push({
              ...item,
              title: pageTitle,
            });
          }
        }
        break;

      default:
        return;
    }
  });

  return results;
};

//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
export function createLoadOptions(facets: Record<string, string[]> | undefined, paramsPage: number | undefined, paramsCount: number | undefined, options: Options | undefined, listProductAmount: number) {

    const pageIndex = (options && options.page) || (paramsPage ? paramsPage - 1 : 0);
    const count = (options && options.size) || paramsCount;

    const loadOptions = {
        page: {
            index: pageIndex,
            size: count || listProductAmount,
        },
        facets: getFacets(facets),
    };

    return loadOptions;
}

//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
function getFacets(facets: Record<string, string[]> | undefined): Facets | undefined {
    if (facets) {
        const facetsArr = Object.entries(facets).map(([name, values]) => ({ name, values }));
        if (facetsArr.length)
            return { filter: facetsArr };
    }

    return undefined;
}

type Media = Array<{
  type: ProductMediaType.Image;
  small: string | null;
  medium: string | null;
  large: string | null;
  title: string | null;
} | {
  type: ProductMediaType.Video;
  url: string;
}> | null;

type ProcessedImage = {
  type: ProductMediaType.Image;
  small: string | null;
  medium: string | null;
  large: string | null;
  title: string;
};

type ProcessedMedia = Array<ProcessedImage | {
  type: ProductMediaType.Video;
  videoData: {
    provider: VideoProviderType;
    videoId: string;
  };
  title: string;
}>;
