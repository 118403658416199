import type { Handler } from '../types';
import type { Product, SpecificationSettings, Specification } from './types';
import type { Api } from 'utils/api';
import { PageComponentNames } from 'behavior/pages/componentNames';
import type { SystemPage, SystemPageData } from '../system';
import { of } from 'rxjs';
import { map, first, switchMap, pluck } from 'rxjs/operators';
import { sortByIds, buildSpecificationsModel } from './util';
import { getProductIdsToCompare } from 'behavior/productComparison/util';
import { initPageContent } from '../system';
import { loadPageQuery, loadPageWithProductsQuery } from './queries';
import { areSettingsLoaded, LoadedSettings } from 'behavior/settings';
import { RouteName } from 'routes';

type ProductComparisonRouteData = {
  routeName: RouteName.ProductComparison;
  params: {
    productIds?: string[];
    previewToken?: string;
  };
  options?: {
    keepPreviousState: boolean;
  };
};

type ProductComparisonPage = SystemPage & {
  component: PageComponentNames.ProductComparison;
  products: Product[];
  specifications?: Specification[];
};

type ProductComparisonSystemPageResponse = {
  pages: {
    productComparison: SystemPageData;
  };
};

type ProductComparisonPagePreviewResponse = ProductComparisonSystemPageResponse;

type ProductComparisonPageResponse = ProductComparisonSystemPageResponse & {
  catalog: {
    products: {
      products: Product[];
    } | null;
  };
};

const handler: Handler<ProductComparisonRouteData, ProductComparisonPage> = ({
  params: {
    productIds = [],
    previewToken,
  },
  options,
}, state$, { api }) => {
  if (previewToken) {
    return state$.pipe(
      pluck('settings'),
      first(areSettingsLoaded),
      switchMap(({ product: { productComparison } }) => api.graphApi<ProductComparisonPagePreviewResponse>(loadPageQuery).pipe(
        map(({ pages: { productComparison: page } }) => {
          if (!page)
            return null;

          const result = {
            page: initPageContent(page) as ProductComparisonPage,
          };

          const products = Array.from(Array(Math.min(5, productComparison.maxProductsToCompare))).map((_, index) => ({
            id: index.toString(),
            title: null,
            image: null,
            url: null,
            price: null,
            listPrice: null,
            stockLevels: null,
            inventory: null,
            isOrderable: null,
            hasVariants: null,
            productConfiguratorInfo: null,
            uom: null,
            specifications: [],
            reviews: null,
          }));
          result.page.component = PageComponentNames.ProductComparison;
          result.page.products = products;
          result.page.specifications = productComparison.specifications.map(spec => ({
            ...spec,
            values: products.map(() => ({ value: null })),
            hasDifferences: false,
          }));

          return result;
        }),
      )),
    );
  }

  if (options && options.keepPreviousState)
    return of({ page: state$.value.page as ProductComparisonPage });

  return state$.pipe(
    first(state => state.settings.loaded && !!state.analytics),
    switchMap(
      ({ settings, analytics }) => {
        const { product } = settings as LoadedSettings;
        return product.productComparison.isEnabled
          ? loadPage(
            api,
            getProductIdsToCompare(productIds, product.productComparison),
            product.productComparison,
            analytics!,
            state$.value.insiteEditor.initialized,
          ) : of(null);
      },
    ),
  );
};

export default handler;

function loadPage(
  api: Api,
  ids: string[],
  settings: { specifications: SpecificationSettings },
  analytics: { isTrackingEnabled: boolean },
  isInsiteEditor: boolean,
) {
  const { query, params } = createApiRequest(ids, analytics, isInsiteEditor);

  return api.graphApi<ProductComparisonPageResponse>(query, params).pipe(
    map(result => mapResult(result, ids, settings)),
  );
}

function mapResult(
  {
    pages: {
      productComparison: page,
    },
    catalog,
  }: ProductComparisonPageResponse,
  ids: string[],
  settings: { specifications: SpecificationSettings },
) {
  if (!page)
    return null;

  const result = {
    page: initPageContent(page) as ProductComparisonPage,
  };

  result.page.component = PageComponentNames.ProductComparison;
  result.page.products = catalog && sortByIds(catalog.products?.products, ids);
  result.page.specifications = buildSpecificationsModel(settings.specifications, result.page.products);
  return result;
}

function createParamsForProducts(ids: string[]) {
  return {
    options: {
      ids,
      page: {
        size: ids.length,
      },
      ignoreGrouping: true, //[180314] Issue in Product Comparison for the Items having Same Title
    },
  };
}

function createApiRequest(ids: string[], analytics: { isTrackingEnabled: boolean }, isInsiteEditor: boolean) {
  if (ids && ids.length) {
    return {
      query: loadPageWithProductsQuery({ isInsiteEditor }),
      params: {
        ...createParamsForProducts(ids),
        loadCategories: analytics.isTrackingEnabled,
      },
    };
  }

  return {
    query: loadPageQuery,
  };
}
