import type { CalculatedProduct, ProductGroupLoadOptions } from './types';

export const PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED = 'PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED' as const;
export const requestCalculatedFields = (id: string, options?: ProductGroupLoadOptions) => ({ //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
  type: PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED,
  payload: { id, options }, //[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
});

export const PRODUCTGROUP_PRODUCTS_UPDATED = 'PRODUCTGROUP_PRODUCTS_UPDATED' as const;
export const productsUpdated = (products: CalculatedProduct[] | null) => ({
  type: PRODUCTGROUP_PRODUCTS_UPDATED,
  payload: products,
});

export type ProductGroupAction = ReturnType<
  | typeof requestCalculatedFields
  | typeof productsUpdated
>;
