//[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages

import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import DefaultPaging from '../paging';
import ListContext from './ListContext';

const Paging = ({ totalCount, loadedCount, containerId }) => {
  const { quantities } = useContext(ListContext);

  return (
    <DefaultPaging
      totalCount={totalCount}
      loadedCount={loadedCount}
      containerId={containerId}
    />
  );
};

Paging.propTypes = {
  totalCount: PropTypes.number,
  loadedCount: PropTypes.number,
  containerId: PropTypes.string.isRequired,
};

export default memo(Paging);
