import styles from './RequestsQuote.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useEffect, useState } from 'react';
import { Link } from 'components/primitives/links';
import { connect, useSelector } from 'react-redux';
import { useSimpleTexts, UseSanaTexts, SimpleText } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { Modal, useModal } from 'components/objects/modals';
import { FormName } from 'behavior/pages';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import { SanaForm, FormGroup, useFormLockerOnSubmit, TextAreaField } from 'components/objects/forms';
import { toasts } from 'behavior/toasts';
import { sendRequestsQuote } from 'behavior/pages/product';

//TICKET 156337 - 3.16.Product which are Available on demand
const RequestsQuote = ({ product, alignmentClassName, sendRequestsQuote, savingResult }) => {

  const { isAuthenticated } = useSelector(state => state.user);

  const [isSpinnerDisplayed, displaySpinner] = useState(false);
  const { id, title } = product;
  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  const messagePlaceholder = <Placeholder className={styles.messagePlaceholder} />;

  const { texts } = useRequestsQuoteTexts();
  const { opened, show, hide } = useModal();

  const requestsQuote = () => {
    show();
  };

  useEffect(() => {
    if (!savingResult)
      return;

    const { failureText } = savingResult;
    const { productId } = savingResult;

    if (productId !== id)
      return;

    if (failureText === undefined)
      return;

    hide();
    displaySpinner(false);

    if (failureText === null) {
      toasts.success(texts.sendSuccessfulPopup, { className: styles.toast });
    } else {
      toasts.error(failureText, { className: styles.toast });
    }
  }, [savingResult]);

  const handleSubmit = useFormLockerOnSubmit(
    formData => {
      displaySpinner(true);
      sendRequestsQuote(id, formData.comment)
    },
    FormName.SendRequestsQuote,
    [],
  );

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <>

      <SanaButton
        textKey="RequestsQuote"
        onClick={requestsQuote}
        className={`${btnStyles.btnAction} ${btnStyles.btnBig} ${styles.requestsQuoteBtn} ${alignmentClassName}`}
        placeholder={btnPlaceholder}
      />

      <Modal opened={opened} hide={hide} className={`${styles.sendRequestsQuote}`} resetContentOnClose>
        <h1 className={`h1 ${modalStyles.title}`}>{title}</h1>
        <span className={`h4 ${modalStyles.texts} ${styles.greyColorLabel}`}>{`${texts.itemNo} ${id}`}</span>
        <UseSanaTexts options={['RequestQuote_Message']} placeholder={messagePlaceholder}>
          {([message]) => {
            return (message && (
              <p className={`${styles.message}`}>
                {makeRichText(message.replace('[PRODUCTNAME]', title), [title])}
              </p>
            ));
          }}
        </UseSanaTexts>

        <SanaForm
          name="SendRequestsQuote"
          onSubmit={handleSubmit}
          shouldReset={savingResult}
        >
          <FormGroup
            fieldName="comment"
            fieldTitle={texts.comment}
            fieldComponent={TextAreaField}
            maxLength={500}
            autoFocus
          />
          <div className={styles.sendRequestsQuoteActions}>
            <SanaForm.SubmitRow textKey="SendRequestQuote" btnClass={isSpinnerDisplayed ? btnStyles.loading : null} />
          </div>
        </SanaForm>
      </Modal>

    </>
  );
};

RequestsQuote.propTypes = {
  alignmentClassName: PropTypes.string.isRequired,
  sendRequestsQuote: PropTypes.func.isRequired,
};

export default connect(({ page }) => ({
  savingResult: page.requestsQuoteResult,
}), { sendRequestsQuote })(RequestsQuote)

function useRequestsQuoteTexts() {
  const {
    texts: [
      sendRequestQuoteBtn,
      cancelBtn,
      message,
      comment,
      itemNo,
      sendSuccessfulPopup,
    ],
    loaded: textsLoaded,
  } = useSimpleTexts([
    'ButtonText_SendRequestQuote',
    'ButtonText_Cancel',
    'RequestQuote_Message',
    'RequestQuote_Comment',
    'RequestQuote_ItemNo',
    'RequestQuote_SendSuccessfully'
  ]);

  return {
    texts: {
      sendRequestQuoteBtn,
      cancelBtn,
      message,
      comment,
      itemNo,
      sendSuccessfulPopup,
    },
    textsLoaded,
  };
}
