import { createContext } from 'react';
import { ComponentGroup } from 'behavior/navigation/constants';

type CascadingMenuContext = {
  componentGroup: ComponentGroup.CascadingMainMenu;
};

type MultiColumnMenuContext = {
  componentGroup: ComponentGroup.MultiColumnMainMenu;
  showViewMoreLink: boolean;
};

//[156332] [Siremballage] 3.10. Custom menu - [F1]
type CustomMenuContext = {
  componentGroup: ComponentGroup.CustomMainMenu;
  showViewMoreLink: boolean;
};

export default createContext<CascadingMenuContext | MultiColumnMenuContext | CustomMenuContext>({
  componentGroup: ComponentGroup.CascadingMainMenu,
});
