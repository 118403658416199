import type { Product } from './types';
import type { ProductGroupAction } from './actions';
import { createReducer } from 'utils/redux';
import { PRODUCTGROUP_PRODUCTS_UPDATED, productsUpdated } from './actions';

type ProductGroupPageState = {
  title: string | null;
  products: Array<Product> | null;
  productsSf: Array<Product> | null; //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
};

const initialState: ProductGroupPageState = {
  title: null,
  products: [],
  productsSf: [], //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
};

export default createReducer<ProductGroupPageState, ProductGroupAction>(initialState, {
  [PRODUCTGROUP_PRODUCTS_UPDATED]: calculatedFieldsLoaded,
});

function calculatedFieldsLoaded(state: ProductGroupPageState, action: ReturnType<typeof productsUpdated>) {
  if ((!state.products && !state.productsSf) || !action.payload)
    return state;

  const calculatedProducts = action.payload;

  //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
  let updatedProducts: Array<Product>;
  let updatedProductsSf: Array<Product>;

  updatedProducts = [];
  updatedProductsSf = [];


  if (state.products) {
    updatedProducts = state.products.map(product => {
        const calculatedInfo = calculatedProducts.find(p => p.id === product.id);

        if (!calculatedInfo)
            return { ...product, calculatedInfoChanged: false };

        //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
        calculatedInfo.price = calculatedInfo.groupPrice;
        calculatedInfo.listPrice = calculatedInfo.groupListPrice;

        const calculatedInfoChanged = typeof calculatedInfo.price !== 'undefined';
        calculatedInfo.specifications = calculatedInfo.groupSpecifications; //[156335] [F1] [Siremballage] 3.14. Level 3 product group pages
        return { ...product, ...calculatedInfo, calculated: true, calculatedInfoChanged };
    });
  }

  if (state.productsSf) {
    updatedProductsSf = state.productsSf.map(product => {
        const calculatedInfo = calculatedProducts.find(p => p.id === product.id);

        if (!calculatedInfo)
            return { ...product, calculatedInfoChanged: false };

        //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
        calculatedInfo.price = calculatedInfo.groupPrice;
        calculatedInfo.listPrice = calculatedInfo.groupListPrice;

        const calculatedInfoChanged = typeof calculatedInfo.price !== 'undefined';
        calculatedInfo.specifications = calculatedInfo.groupSpecifications; //[156335] [F1] [Siremballage] 3.14. Level 3 product group pages
        return { ...product, ...calculatedInfo, calculated: true, calculatedInfoChanged };
    });
  }

  return {
    ...state,
    products: updatedProducts,
    productsSf: updatedProductsSf, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
  };
}
