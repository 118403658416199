import type { ComponentGroup, NavigationGroupCode } from './constants';
import type { NavItem, MainMenuItem } from './types';

export const NAVIGATION_LOAD = 'NAVIGATION_LOAD' as const;
export const loadNavigation = (componentGroup: ComponentGroup, groupCode: NavigationGroupCode) => ({
  type: NAVIGATION_LOAD,
  payload: { componentGroup, groupCode },
});

export const NAVIGATION_LOADED = 'NAVIGATION_LOADED' as const;
export const navigationLoaded = (componentGroup: ComponentGroup, groupCode: NavigationGroupCode, items: NavItem[] | null) => ({
  type: NAVIGATION_LOADED,
  payload: { componentGroup, groupCode, items },
});

type MainNavigationComponentGroup = ComponentGroup.CascadingMainMenu | ComponentGroup.MultiColumnMainMenu | ComponentGroup.CustomMainMenu; //[156332] [Siremballage] 3.10. Custom menu - [F1]

export const MAIN_NAVIGATION_CHILDREN_LOAD = 'MAIN_NAVIGATION_CHILDREN_LOAD' as const;
export const loadMainNavigationChildren = (componentGroup: MainNavigationComponentGroup, parentId: string) => ({
  type: MAIN_NAVIGATION_CHILDREN_LOAD,
  payload: { componentGroup, parentId },
});

export const MAIN_NAVIGATION_CHILDREN_LOADED = 'MAIN_NAVIGATION_CHILDREN_LOADED' as const;
export const mainNavigationChildrenLoaded = (componentGroup: MainNavigationComponentGroup, parentId: string, children: MainMenuItem[] | null) => ({
  type: MAIN_NAVIGATION_CHILDREN_LOADED,
  payload: { componentGroup, parentId, children },
});

export type NavigationLoaded = ReturnType<typeof navigationLoaded>;
export type MainNavigationChildrenLoaded = ReturnType<typeof mainNavigationChildrenLoaded>;

export type NavigationAction =
  | NavigationLoaded
  | MainNavigationChildrenLoaded
  | ReturnType<
    | typeof loadNavigation
    | typeof loadMainNavigationChildren
  >;
