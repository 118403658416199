export const enum NavigationGroupCode {
  Main = 'MAIN',
  Footer = 'FOOTER',
  Sitemap = 'SITEMAP',
  MyAccount = 'MY_ACCOUNT',
}

export const enum ImagePosition {
  Inline = 'INLINE',
  RightBottom = 'RIGHT_BOTTOM',
}

export const enum ComponentGroup {
  Default = 'Default',
  CascadingMainMenu = 'CascadingMainMenu',
  MultiColumnMainMenu = 'MultiColumnMainMenu',
  CustomMainMenu = 'CustomMainMenu', //[156332] [Siremballage] 3.10. Custom menu - [F1]
  LinkMenu = 'LinkMenu',
}
