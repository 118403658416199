import {
  RegistrationAction,
  REGISTRATION_PROCESSED,
  REGISTRATION_TEMPLATEFIELDS_RECEIVED,
  REGISTEREDIN_ERP, //155284_Siremballage_3.2_B2B_Customer_Registration
} from './actions';
import type { TemplateFields, RegistrationResult } from './types';

type State = {
  registrationResult: RegistrationResult;
    templateFields: TemplateFields | null;
    isAlreadyRegisterd: boolean //155284_Siremballage_3.2_B2B_Customer_Registration
};

const initialState = {
  registrationResult: {
    failureText: null,
    isRegistered: false,
    personalizedPricePending: false, //155284_Siremballage_3.2_B2B_Customer_Registration
    siretCodeNotFound: false, //155284_Siremballage_3.2_B2B_Customer_Registration
  },
  templateFields: null,
  isAlreadyRegisterd: true, //155284_Siremballage_3.2_B2B_Customer_Registration
};

export default (state = initialState, action: RegistrationAction): State => {
  switch (action.type) {
    case REGISTRATION_PROCESSED:
      return { ...state, registrationResult: action.payload };
    case REGISTRATION_TEMPLATEFIELDS_RECEIVED:
      return { ...state, templateFields: action.payload };
    case REGISTEREDIN_ERP:
      return { ...state, isAlreadyRegisterd: action.payload }; //155284_Siremballage_3.2_B2B_Customer_Registration
    default:
      return state;
  }
};