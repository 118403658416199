import styles from './ProductGroup.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { GroupProductStock } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';
import { ProductStockOnDemand } from 'components/primitives/product';

//TICKET 156337 - 3.16. Product which are Available on demand
//Ticket 225756: Article non disponible mais pastille verte
const Stock = ({ isAvailableOnDemand, stockLevels, inventory, outOfStockThreshold }) => {
  const isPreview = useIsPreview();

  if (inventory === null || stockLevels == null) {
    if (isPreview) {
      return (
        <span>
          <Placeholder className={styles.stockPreviewPlaceholder} />
        </span>
      );
    }

    return null;
  }

  return (
    <span>
      {/*[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages*/}
      <GroupProductStock
        inventory={inventory}
        stockLevels={stockLevels}
        isAvailableOnDemand={isAvailableOnDemand}
        outOfStockThreshold={outOfStockThreshold} />
    </span>
  );
};

Stock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
};

//Ticket 225756: Article non disponible mais pastille verte
export default connectToContext(
  [ProductContext],
  ({ isAvailableOnDemand, stockLevels, inventory, outOfStockThreshold }) => ({ isAvailableOnDemand, stockLevels, inventory, outOfStockThreshold }),
)(
  memo(Stock),
);
