//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages

import styles from './Facets.module.scss';
import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import SelectedFacets from './SelectedFacets';
import AvailableFacets from './AvailableFacets';
import { Placeholder } from 'components/primitives/placeholders';
import FacetsContext from './FacetsContext';
import { UseSanaTexts } from 'components/sanaText';
import { Row, Col } from 'components/primitives/grid';
import { Link } from 'components/primitives/links';

const textsToLoad = ['Filters'];

const Facets = ({ isMobile }) => {
  const {
    multiSelect,
    hasFacets,
    selectedFacets,
    availableFacets,
    getUrl,
    getAllUrl,
    urlWithoutFacets,
  } = useContext(FacetsContext);

  if (!hasFacets)
    return null;

  return (
    <UseSanaTexts options={textsToLoad} dontWait>{
      ([filtersText]) => (
        <nav className={`${isMobile ? `${styles.mobile} ` : ''}${styles.facets}`} aria-label={filtersText}>
          <Row>
            <Col md="9" xs="12">
              {availableFacets.length > 0
                ? <AvailableFacets facets={availableFacets} isMobile={isMobile} getUrl={getUrl} getAllUrl={getAllUrl} multiSelect={multiSelect} />
                : isMobile
                  ? null
                  : <Placeholder className={styles.facetsPlaceholder} lineCount={8} />
              }
            </Col>
            <Col md="3" className={`${styles.clearFilterBtnCol}`} xs="12">
              {selectedFacets.length > 0 &&
                <SelectedFacets facets={selectedFacets} getUrl={getUrl} getEmptyUrl={urlWithoutFacets} />
              }
              <Link id="clearFacets" omitScroll {...urlWithoutFacets()} style={{ display: 'none' }} />{/*[156335] [TABS] [Siremballage] 3.14. Level 3 product group pages*/}
            </Col>
          </Row>
        </nav>
      )}
    </UseSanaTexts>
  );
};

Facets.propTypes = {
  isMobile: PropTypes.bool,
};

export default memo(Facets);
