//[156332] [Siremballage] 3.10. Custom menu - [F1]

import styles from './MainNav.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { shouldRenderNavigation } from '../helpers';
import { MainNavItemPropTypes } from './PropTypes';
import { setFocus } from './eventHandlers';
import { useNavMenuContext } from './hooks';

const CustomMultiColumnView = ({
  item: {
    title,
    cssClass,
    link,
    children: subItems,
  },
  ariaSubmenuText,
}) => {
  const { showViewMoreLink } = useNavMenuContext();

  return (
    <li className={`${styles.navItemBlock} ${cssClass || ''}`}>
      <Link {...link} className={styles.hypSecondLvl} onClick={setFocus} onContextMenu={setFocus}>
        {title}
      </Link>
      {shouldRenderNavigation(subItems) && (
        <ul className={styles.navList} aria-label={`${title} ${ariaSubmenuText}`}>
          {subItems.map(({ id, title, cssClass, link }) => {
            return (
              <li key={id} className={cssClass || null}>
                <Link {...link} className={styles.hypDefault} onClick={setFocus} onContextMenu={setFocus}>
                  {title}
                </Link>
              </li>
            );
          })}
          {showViewMoreLink && link != null && !!link.url && link.url !== '#' && (
            <li>
              <Link {...link} className={styles.hyp} onClick={setFocus}>
                <SimpleText textKey="ViewMore" /> {title}
              </Link>
            </li>
          )}
        </ul>
      )}
    </li>
  );
};

CustomMultiColumnView.propTypes = {
  item: MainNavItemPropTypes,
  ariaSubmenuText: PropTypes.string,
};

export default memo(CustomMultiColumnView);
