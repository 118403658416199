import DesktopCascadingMainMenu from './mainNav/desktop/CascadingMainMenuBlock';
import DesktopMultiColumnMainMenu from './mainNav/desktop/MultiColumnMainMenuBlock';
import DesktopCustomMainMenu from './mainNav/desktop/CustomMainMenuBlock'; //[156332] [Siremballage] 3.10. Custom menu - [F1]
import MobileMainMenu from './mainNav/mobile/MainMenuBlock';
import LinkMenu from './linkMenu/LinkMenuBlock';

export default {
  DesktopCascadingMainMenu,
  DesktopMultiColumnMainMenu,
  DesktopCustomMainMenu, //[156332] [Siremballage] 3.10. Custom menu - [F1]
  MobileMainMenu,
  LinkMenu,
};
