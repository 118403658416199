//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages

import styles from './Facets.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VerticalSliding } from 'components/primitives/transitions';
import { UseSanaTexts } from 'components/sanaText';
import { makeRichText, makeSimpleText } from 'utils/render';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import { CheckedIcon, MediumArrowUpIcon, MediumArrowDownIcon } from 'components/primitives/icons';
import { isBrowser } from 'utils/detections';

const Facet = ({ facet, getUrl, multiSelect, isMobile, getAllUrl }) => {
  const [expanded, setExpanded] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const facetValuesLinkRel = !facet.crawlable ? 'nofollow' : null;
  const toggleExpand = () => setExpanded(!expanded);
  const toggleOnKey = e => {
    if (e.key !== ' ' && e.key !== 'Enter')
      return;

    setExpanded(!expanded);
    e.preventDefault();
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setExpanded(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  
  let values = facet.values;
  let hasSelected = values.some(function (e) { return e.selected });

  let allSelected = facet.values.every(e => e.selected);
  const linkDataForAll = getAllUrl && getAllUrl(facet, facet.values, allSelected);


  return (
    <section className={`${expanded ? `${styles.expanded} ` : ''}${styles.facet} ${styles.facetDropDownBlock}`} aria-label={facet.title} ref={wrapperRef}>
      <div role="button" className={`${styles.header} ${hasSelected ? styles.hasSelected : ''}`} onClick={toggleExpand} onKeyDown={toggleOnKey} tabIndex="0"
        aria-expanded={expanded}      
      >
        <h3 className="h4">{makeSimpleText(facet.title)}</h3>
        <span className={styles.expandIcon} aria-hidden>
          {expanded ? <MediumArrowUpIcon /> : <MediumArrowDownIcon />}
        </span>
      </div>
      <VerticalSliding expanded={expanded} containerClass={styles.slider}>
        <ul className={`${styles.values}`}
          aria-label={facet.title}
          suppressHydrationWarning // It is expected that after hydration there will be only 5 items shown. Initial HTML is enough for crawlers.
        >
          <li className={`${multiSelect ? '' : `${linkStyles.arrowed} `}${styles.item}`}>
            <Link
              {...linkDataForAll}
              className={`${multiSelect ? `${styles.multiSelect} ` : ''}${styles.itemTitle}`}
              omitScroll
          >
        
              {multiSelect &&
                <span className={styles.checkbox}>
                  {allSelected &&
                    <span className={styles.checked}>
                      <CheckedIcon className={styles.icon} />
                    </span>
                  }
                </span>
              }
              <span className={styles.titleText}>
                Select all
              </span>
         
            </Link>
          </li>
          {values.map((value, index) => {
            const linkData = getUrl && getUrl(facet, value);
            const valueTextTitle = value.textTitle && makeSimpleText(value.textTitle) || null;
            return (
              <li key={index} className={`${multiSelect ? '' : `${linkStyles.arrowed} `}${styles.item}`}>
                <Link
                  {...linkData}
                  className={`${multiSelect ? `${styles.multiSelect} ` : ''}${styles.itemTitle}`}
                  title={valueTextTitle}
                  aria-label={valueTextTitle}
                  rel={facetValuesLinkRel}
                  omitScroll
                >
                  {multiSelect &&
                    <span className={styles.checkbox}>
                      {value.selected &&
                        <span className={styles.checked}>
                          <CheckedIcon className={styles.icon} />
                        </span>
                      }
                    </span>
                  }
                  <span className={styles.titleText}>
                    {makeRichText(value.title)}
                  </span>
                  {!value.selected && <span className={styles.itemCount}>({value.count})</span>}
                </Link>
              </li>
            );
          })}
        </ul>
      </VerticalSliding>
    </section>
  );
};

Facet.propTypes = {
  facet: PropTypes.object,
  isMobile: PropTypes.bool,
  getUrl: PropTypes.func.isRequired,
  getAllUrl: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool.isRequired,
};

export default memo(Facet);
