import styles from './ProductGroup.module.scss';
import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connectToContext } from 'utils/react';
import ProductGroupContext from './ProductGroupContext';
import { UomSelector, useCanViewUom, QuantityTextBox, createQuantityModel, UomTitle } from 'components/primitives/product';
import { requestCalculatedFields } from 'behavior/pages/productGroup';
import { addProducts } from 'behavior/basket';
import { addToBasket } from './AddToBasketButton';
import { getNumberDecimalsSeparator } from 'utils/format';
import { useIsVisualDesigner } from 'utils/hooks';
import { AddToWishlistButton } from 'components/objects/productGroup/wishlist';
import { CartSummaryIcon } from 'components/primitives/icons';
import btnStyles from './ProductGroup.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { toasts } from 'behavior/toasts';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const QuantityBox = ({
  canOrderProducts,
  allowUOMSelection,
  updateQuantity,
  product,
  quantities,
  quantityValue,
  addProducts,
  resetQuantities,
  requestCalculatedFields,
  culture,
  groupId,
}) => {
  const { uoms, id: productId, isOrderable } = product;

  const canViewUom = useCanViewUom();
  const [uomId, updateUomId] = useState(product.uom && product.uom.id);
  const showUomSelector = canViewUom && uomId && uoms && uoms.length > 0;

  const [enterQty, invalidQty, successAdd] = useSanaTexts(['ProductGroup_EmptyQty', 'ProductGroup_InvalidQty', 'ProductGroup_AddedToBasket']).texts; //[156335] [TABS] [Siremballage] 3.14. Level 3 product group pages

  const updateUom = useCallback(uomId => {
    updateUomId(uomId);
    const options = { ids: [productId], uomId };
    updateQuantity(productId, uomId, null);
    requestCalculatedFields(groupId, options);
  }, [productId, updateQuantity, requestCalculatedFields]);

  const quantityModel = useMemo(() => {
    const uom = uoms && uoms.find(u => u.id === uomId);

    return createQuantityModel(uom, true);
  }, [uomId]);

  const handleQuantityChange = useCallback(quantity => {
    updateQuantity(productId, uomId, quantity);
  }, [uomId]);

  const handleEnter = useCallback(({ which, key }) => {
    if (key === 'Enter' || which === 13)
      addToBasket(quantities, resetQuantities, addProducts);
  }, [quantities, resetQuantities, addProducts]);

  //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
  //const addToBasketSingle = useCallback(() => {
  //  let productQuantity = quantities.get(productId);
  //  if (!productQuantity || productQuantity.uomId !== uomId) {
  //    toasts.info(`${productId} - ${makeSimpleText(enterQty)}`);
  //    return;
  //  }

  //  if (!productQuantity.quantity.isValid) {
  //    toasts.error(`${productId} - ${makeSimpleText(invalidQty)}`);
  //    return;
  //  }

  //  addProducts([{
  //    productId,
  //    uomId,
  //    quantity: productQuantity.quantity.value,
  //  }]);
  //  updateQuantity(productId, uomId, null);

  //  /*[156335] [TABS] [Siremballage] 3.14. Level 3 product group pages*/
  //  toasts.success(`${productId} - ${makeSimpleText(successAdd)}`);

  //}, [productId, quantities, updateQuantity]);

  //Ticket 226036: MESSAGE INTENPESTIF "NULL" PB15
  const handleClick = function () {
    let productQuantity = quantities.get(productId);
    if (!productQuantity || productQuantity.uomId !== uomId) {
      toasts.info(`${productId} - ${makeSimpleText(enterQty)}`);
      return;
    }

    if (!productQuantity.quantity.isValid) {
      toasts.error(`${productId} - ${makeSimpleText(invalidQty)}`);
      return;
    }

    addProducts([{
      productId,
      uomId,
      quantity: productQuantity.quantity.value,
    }]);
    updateQuantity(productId, uomId, null);

    //[156335] [TABS] [Siremballage] 3.14. Level 3 product group pages
    toasts.success(`${productId} - ${makeSimpleText(successAdd)}`);
  }

  const separator = getNumberDecimalsSeparator(culture);

  //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
  const isDesignerMode = useIsVisualDesigner();
  const uomIdUpper = uomId.toUpperCase();
  const currentUom = uoms.find(u => u.id.toUpperCase() === uomIdUpper) || {};

  return (
    <>
      {isOrderable &&
        <div className={styles.quantityBox}>
          {canOrderProducts && (
            //[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages
            <>
              <div>
                <div className={styles.quantityTextBox}>
                  {quantityModel && (
                    <QuantityTextBox
                      id={`${productId}_qty`}
                      quantity={quantityModel}
                      value={quantityValue}
                      onChange={handleQuantityChange}
                      onKeyDown={handleEnter}
                      separator={separator}
                      allowResetOnStepDecrease
                    />
                  )}
                </div>
                {showUomSelector && (
                  <div className={styles.uomSelector}>
                    <div className={styles.uomTitle}>
                      <UomTitle id={currentUom.id} description={currentUom.description} />
                    </div>
                  </div>
                )}
              </div>
              <AddToWishlistButton product={product} isDesignerMode={isDesignerMode} />
              <button className={`${linkStyles.link} ${btnStyles.addToCartBtn}`}
                onClick={isDesignerMode ? null : handleClick} type="button">
                <CartSummaryIcon />
              </button>
            </>
          )}
        </div>
      }
    </>
  );
};

QuantityBox.propTypes = {
  canOrderProducts: PropTypes.bool.isRequired,
  allowUOMSelection: PropTypes.bool.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  product: PropTypes.shape({
    uoms: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool.isRequired,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  quantities: PropTypes.instanceOf(Map).isRequired,
  quantityValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addProducts: PropTypes.func.isRequired,
  resetQuantities: PropTypes.func.isRequired,
  requestCalculatedFields: PropTypes.func.isRequired,
  culture: PropTypes.string,
  groupId: PropTypes.string.isRequired,
};

const connectedQuantityBox = connect(
  ({ settings, localization, page: { id: groupId } }) => ({
    allowUOMSelection: settings.product && settings.product.allowUOMSelection,
    culture: localization.currentLanguage && localization.currentLanguage.cultureName,
    groupId,
  }),
  { requestCalculatedFields, addProducts },
)(QuantityBox);

export default connectToContext([ProductGroupContext], ({ quantities, updateQuantity, resetQuantities }, { product }) => {
  const productQuantity = quantities.get(product.id);

  return {
    quantities,
    quantityValue: productQuantity && productQuantity.quantity && productQuantity.quantity.value,
    updateQuantity,
    resetQuantities,
  };
})(connectedQuantityBox);
