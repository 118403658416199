//[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages

import { memo } from 'react';
import PropTypes from 'prop-types';
import LoadMoreButton from './LoadMoreButton';

const Paging = ({ totalCount, loadedCount, containerId, ...props }) => {
  return <LoadMoreButton totalCount={totalCount} loadedCount={loadedCount} containerId={containerId} />;
};

Paging.propTypes = {
  totalCount: PropTypes.number,
  loadedCount: PropTypes.number, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
  containerId: PropTypes.string.isRequired, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
};

export default memo(Paging);