//[156332] [Siremballage] 3.10. Custom menu - [F1]

import styles from './CustomMenu.module.scss';
import styles1 from '../MainNav.module.scss';

import { memo, useRef, useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { loadMainNavigationChildren } from 'behavior/navigation';
import { ComponentGroup } from 'behavior/navigation/constants';
import { Link } from 'components/primitives/links';
import { VerticalSliding } from 'components/primitives/transitions';
import ListItemStateIcon from '../ListItemStateIcon';
import CustomMenuViewSubItems from './CustomMenuViewSubItems';
import Spinner from 'components/primitives/spinner/Spinner';
import { shouldRenderNavigation, isRightArrowKeyPressed, isLeftArrowKeyPressed } from '../../helpers';
import { isModifierKeyPressed } from 'utils/helpers';
import { useOnPageChanged } from 'utils/hooks';
import { MainNavItemPropTypes, SublistParamsPropTypes } from '../PropTypes';
import { useNavItemEventHandlersData } from '../hooks';
import { setFocus, hoveredListClass, hoveredItemClass } from '../eventHandlers';
import CustomMultiColumnView from '../CustomMultiColumnView';
import { set } from 'lodash';

import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';

const CustomMenuView = ({
  item: {
    id,
    title,
    cssClass,
    link,
    openIcon,
    children: subItems,
    image,
    description
  },
  isAccordion,
  ariaSubmenuText,
  subListLevel,
  sublistParams,
}) => {
  const timeoutId = useRef();
  const linkRef = useRef(null);
  const [isVisible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const shouldRenderSubItems = shouldRenderNavigation(subItems);
  const childrenCanBeLoaded = subItems && !shouldRenderSubItems;
  const {
    navItemEventHandlers, navItemLinkEventHandlers,
    shouldHandleTouchScreenInteraction, isMobileSafari, isNotMousePointer,
  } = useNavItemEventHandlersData(!!subItems);
  const shouldHandlePointerInteractionOrAria = !isAccordion && shouldRenderSubItems;

  const listItemEventHandlers = useMemo(
    () => isAccordion ? { onClick: navItemEventHandlers.onClick } : navItemEventHandlers,
    [isAccordion, navItemEventHandlers],
  );
  const linkEventHandlers = useMemo(
    () => isAccordion ? { onClick: setFocus, onContextMenu: setFocus } : navItemLinkEventHandlers,
    [isAccordion, navItemLinkEventHandlers],
  );

  const createItem = useCallback(subItem => (
    <CustomMultiColumnView
      key={subItem.id}
      item={subItem}
      //isAccordion={isAccordion}
      ariaSubmenuText={ariaSubmenuText}
    //subListLevel={subListLevel + 1}
    //sublistParams={sublistParams}
    />
  ), [isAccordion, ariaSubmenuText, subListLevel, sublistParams]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
      setVisible(shouldRenderSubItems);
    }
  }, [subItems]);

  useEffect(() => {

    const MegaMenuLink = document.querySelector('.MainNav_hyp-container.MainNav_has-sub-items');

    MegaMenuLink.addEventListener('click', function (e) {
      setTimeout(() => {
        const initialLeftMenu = document.querySelector(".MainNav_desktop.MainNav_custom .MainNav_nav-item-root > .MainNav_sub-items")
        const initialLeftMenuHeight = document.querySelector(".MainNav_desktop.MainNav_custom .MainNav_nav-item-root > .MainNav_sub-items").offsetHeight;
        initialLeftMenu.style.minHeight = initialLeftMenuHeight + 'px';
        // console.log(initialLeftMenu);
      }, 100);

    });

    const leftMenu = document.querySelectorAll(".MainNav_has-sub-items + ul.MainNav_sub-items a");
    const leftMenuContainer = document.querySelector(".MainNav_has-sub-items + ul.MainNav_sub-items ");

    // console.log(leftMenuContainer.clientHeight);
    leftMenu.forEach(function (elem) {
      elem.addEventListener('mousemove', function (e) {
        const rightMenu = document.querySelector(".CustomMenu_megaMenuContainer.nav-is-hovered .MainNav_left-pos")
        if (rightMenu) {
          document.querySelector('.MainNav_nav-list-root').closest('nav').classList.add('level1-is-open');

          const actualHeight = rightMenu.clientHeight + 20;
          leftMenuContainer.style.height = actualHeight + 'px';
        }
      });
    });

    leftMenuContainer.addEventListener('mouseleave', function (e) {
      // console.log('leaving');
      document.querySelector('.MainNav_nav-list-root').closest('nav').classList.remove('level1-is-open');

      const rightMenu = document.querySelector(".MainNav_has-sub-items + .MainNav_focusable")
      if (rightMenu) {
        setTimeout(() => {
          rightMenu.style.removeProperty('height');
        }, 200);
      }
    });
    document.addEventListener('mouseup', function (e) {
      const megaMenuContainer = document.querySelector('.nav-is-hovered');
      if (megaMenuContainer) {
        if (!megaMenuContainer.contains(e.target)) {
          const MegaMenuLink = document.querySelector('.MainNav_hyp-container.MainNav_has-sub-items');
          MegaMenuLink.click();
          // console.log("clicked");
        }
      }
    });
  }, []);

  useOnPageChanged(() => setVisible(false));

  const loadSubItems = childrenCanBeLoaded
    ? () => void (dispatch(loadMainNavigationChildren(ComponentGroup.CascadingMainMenu, id)), setLoading(true))
    : null;

  let handleClick = null, handleKeyDown = null;

  if (isAccordion) {
    handleClick = loadSubItems || (e => {
      if (e.target !== e.currentTarget)
        return;

      setVisible(!isVisible);
      linkRef.current.focus();
    });
  }

  if (isAccordion || childrenCanBeLoaded) {
    handleKeyDown = e => {
      const isCorrectTarget = e.target === linkRef.current;
      const isRightArrowKey = isRightArrowKeyPressed(e);

      if (!isCorrectTarget || isModifierKeyPressed(e) || (!isRightArrowKey && !isLeftArrowKeyPressed(e)))
        return;

      if (childrenCanBeLoaded) {
        if (isRightArrowKey) {
          const listItem = e.currentTarget.parentElement;
          loadSubItems();
          listItem.classList.add(hoveredItemClass);
          listItem.parentElement.classList.add(hoveredListClass);
        }
        return;
      }

      setVisible(isRightArrowKey);
    };
  }

  const subItemsBlock = shouldRenderSubItems && (
    <CustomMenuViewSubItems
      subItems={subItems}
      id={id}
      createItem={createItem}
      isAccordion={isAccordion}
      expanded={isAccordion ? isVisible : null}
      title={title}
      ariaSubmenuText={ariaSubmenuText}
      subListLevel={subListLevel}
      sublistParams={sublistParams}
      backgroundImg={image}
      menuDescription={description}
      className="ism"
    />
  );

  // const hypContainerStyles = `${styles.hypContainer} ${subItems ? styles.hasSubItems : ''}`;
  const hypContainerStyles = `${styles.hypContainerStyles} `;



  return (
    <div className={styles.megaMenuContainer}>
      <li
        className={`${styles.megaMenu} ${cssClass || ''}`}
        {...listItemEventHandlers}
        aria-keyshortcuts={subItems ? 'ArrowRight ArrowLeft' : null}
        data-should-handle-aria={shouldHandlePointerInteractionOrAria ? 'true' : null}
        aria-controls={shouldRenderSubItems ? id : null}
        aria-live={subItems ? 'polite' : null}
        aria-atomic={subItems ? 'true' : null}
      >

        <div
          // Element should be focusable for proper event handling in Mobile Safari, Legacy MS Edge and IE11 when using laptop with touch screen.
          tabIndex="-1"
          className={hypContainerStyles}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          // onMouseOver event is supported by onKeyDown instead of onFocus. The links inside will be actual targets.
          // onMouseOver is not reliable when using touch screen on laptop - it is fired twice for touch position and last known mouse cursor position.
          onMouseOver={shouldHandleTouchScreenInteraction || isAccordion ? null : isNotMousePointer ? loadSubItems : () => timeoutId.current = setTimeout(loadSubItems, 200)}
          onMouseLeave={isAccordion || isNotMousePointer ? null : () => clearTimeout(timeoutId.current)}
          onPointerUp={shouldHandleTouchScreenInteraction && !isAccordion ? loadSubItems : null}
          onTouchCancel={!isAccordion && isMobileSafari && isNotMousePointer ? loadSubItems : null}
          onContextMenu={shouldHandleTouchScreenInteraction && !isAccordion ? loadSubItems : null}
          role="presentation"
        >
          <Link
            {...link}
            className={styles1['hyp-first-lvl']}
            ref={linkRef}
            {...linkEventHandlers}
            aria-haspopup={shouldRenderSubItems ? 'true' : null}
          >
            {openIcon != null &&
              <ResponsiveLazyImage src={openIcon} alt={title} className={styles1.img} />
            }
            {openIcon == null &&
              <span className={styles1.img}>
              </span>
            }
            <span>{title}</span>
          </Link>

        </div>

        {isAccordion && subItems && !loading && <ListItemStateIcon active={isVisible} className={styles.listItemIcon} />}
        {loading && <Spinner className={styles.spinner} />}
        {subItemsBlock && (
          isAccordion
            ? <div className={styles.accordion} expanded={isVisible}>{subItemsBlock}</div>
            : subItemsBlock
        )}

      </li>
    </div>

  );
};

CustomMenuView.propTypes = {
  item: MainNavItemPropTypes,
  isAccordion: PropTypes.bool,
  ariaSubmenuText: PropTypes.string,
  subListLevel: PropTypes.number.isRequired,
  sublistParams: SublistParamsPropTypes,
};

export default memo(CustomMenuView);