//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages

import { useMemo, useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { toDashedUrl } from 'utils/url';
import { stripHtmlTagsAndFormatting, flatMap } from 'utils/helpers';
import { makeSimpleText } from 'utils/render';
import FacetsContext from './FacetsContext';
import { getSortOptionKey } from 'behavior/pages/productList';

/**
 * @typedef {Object} FacetValue
 * @property {string} title Field value title.
 * @property {string} textTitle Field value title.
 * @property {string} value Field value.
 * @property {Boolean} selected Whether this value is selected.
 */

/**
 * @typedef {object} FacetInfo
 * @property {string} title Field title.
 * @property {string} name Field name.
 * @property {Boolean} anySelected Indicates whether any value is selected.
 * @property {Boolean} isCrawlable Indicates whether index page if this field selected..
 */

/**
 * @typedef {object} FacetValues
 * @property {Array<FacetValue>} values - Field values
 *
 * @typedef {FacetInfo & FacetValues} Facet
 */

/**
 * @param {Array<Facet>} [allFacets] All facets with their titles.
 * @param {function(options)} urlsBuilder URLs builder.
 * @returns {object} - obj.
 */
export function useFacetUrlsBuilder(allFacets, urlsBuilder) {
  const selector = !allFacets || !allFacets.length
    ? () => ({})
    : ({
      routing: {
        routeData,
        location: { pathname, search },
        canonicalUrl,
      },
    }) => ({
      pathname,
      routeData,
      search,
      canonicalUrl,
    });

  const { pathname, search, routeData, canonicalUrl } = useSelector(selector, shallowEqual);

  return useMemo(() => {
    if (!allFacets || !allFacets.length)
      return null;

    let normalizedSearch = search;
    if (canonicalUrl) {
      normalizedSearch = canonicalUrl.split('?')[1] || '';
      if (search) {
        // Sort and view mode can be absent in canonical URL, but should be preserved after filter change.
        const { sort, viewMode } = routeData.params;
        if (sort && !normalizedSearch.includes('sort='))
          normalizedSearch += '&sort=' + getSortOptionKey(sort.field, sort.ascending);
        if (viewMode && !normalizedSearch.includes('view-mode='))
          normalizedSearch += '&view-mode=' + viewMode.toLowerCase();

        if (normalizedSearch[0] === '&')
          normalizedSearch = normalizedSearch.substring(1);
      }
    }

    normalizedSearch = normalizedSearch
      ? normalizedSearch.replace(/(?:\?|&)(page|count)(?:=(?:[^&]*|$))+?/i, '')
      : '';

    if (normalizedSearch.length && normalizedSearch[0] !== '?')
      normalizedSearch = '?' + normalizedSearch;

    const titlesCache = new Map();
    const encodeTitle = t => {
      let encoded = titlesCache.get(t);
      if (encoded != null)
        return encoded;
      encoded = encode(t);
      titlesCache.set(t, encoded);
      return encoded;
    };

    const options = {
      allFacets,
      pathname,
      search: normalizedSearch,
      encodeTitle,
      encode,
    };

    const builder = urlsBuilder(options);
    const selectedFacets = builder.getSelectedFacets();

    return {
      /**
       * @param {FacetInfo} field The field.
       * @param {FacetValue} value The value from the field to add.
       * @returns {any} construction result.
       */
      add(field, value) {
        const to = () => {
          const facets = {};
          for (const facet of selectedFacets)
            addFacetValues(facets, facet);

          if (field.name in facets)
            facets[field.name].push(value.value);
          else
            facets[field.name] = [value.value];

          return newRouteData(routeData, facets);
        };

        return {
          url: builder.addToUrl(field, value),
          to,
        };
      },
      /**
      * @param {FacetInfo} field The field.
      * @param {FacetValue} value The value from the field to add.
      * @returns {any} construction result.
      */
      remove(field, value) {
        const to = () => {
          const facets = {};
          for (const facet of selectedFacets)
            addFacetValues(facets, facet);

          if (field.name in facets) {
            const facetValues = facets[field.name].filter(v => v !== value.value);

            if (facetValues.length)
              facets[field.name] = facetValues;
            else
              delete facets[field.name];
          }

          return newRouteData(routeData, facets);
        };

        return {
          url: builder.removeFromUrl(field, value),
          to,
        };
      },
      /**
       * @param {FacetInfo} field The field.
       * @param {FacetValue} value The value from the field to add.
       * @returns {any} construction result.
       */
      addAll(field, values) {
        const to = () => {
          const facets = {};
          for (const facet of selectedFacets)
            addFacetValues(facets, facet);

          if (field.name in facets) {
            for (const value of values) {
              facets[field.name].push(value.value);
            }
          }
          else {
            for (const value of values) {
              if (field.name in facets)
                facets[field.name].push(value.value);
              else
                facets[field.name] = [value.value];
            }
          }

          return newRouteData(routeData, facets);
        };
        return {
          url: builder.addToUrlAll(field, values),
          to,
        };
      },
      /**
       * @param {FacetInfo} field The field.
       * @param {FacetValue} value The value from the field to add.
       * @returns {any} construction result.
       */
      removeAll(field, values) {
        const to = () => {
          const facets = {};
          for (const facet of selectedFacets)
            addFacetValues(facets, facet);

          if (field.name in facets) {
            delete facets[field.name];
          }

          return newRouteData(routeData, facets);
        };
        return {
          url: builder.removeFromUrlAll(field, values),
          to,
        };
      },
      removeUrlAll() {
        const to = () => {
          const facets = {};
          return newRouteData(routeData, facets);
        };
        return {
          url: builder.removeAllFromUrl(),
          to,
        };
      },
    };
  }, [allFacets, pathname, canonicalUrl || search, routeData]);
}

function newRouteData(routeData, facets) {
  const params = {
    ...routeData.params,
  };
  delete params.page;
  delete params.facets;
  delete params.count;

  if (facets && Object.keys(facets).length)
    params.facets = facets;
  return { params, routeName: routeData.routeName };
}

function encode(str) {
  return toDashedUrl(stripHtmlTagsAndFormatting(str), 50).toLowerCase();
}

function addFacetValues(to, facet) {
  to[facet.name] = [...facet.values.keys()];
}

export function useSelectedFacetsTitle() {
  const { selectedFacets } = useContext(FacetsContext);

  const facetTitles = useMemo(() => flatMap(
    selectedFacets,
    ({ values }) => values,
    value => value.textTitle || value.title,
  ), [selectedFacets]);

  if (!facetTitles || !facetTitles.length)
    return null;

  return makeSimpleText(facetTitles.join(' - '));
}
