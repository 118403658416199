//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages

import styles from './Facets.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText, makeSimpleText } from 'utils/render';
import { Link } from 'components/primitives/links';
import { Button } from 'components/primitives/buttons';
import { CrossSmallIcon } from 'components/primitives/icons';
import useEditableFacets from './useEditableFacets';

const textsToLoad = [
  'SelectedFacets_Header',
  'RemoveFacetButton',
  'Delete',
  'FacetedSearch_ClearSelection',
];
textsToLoad.skipReplacingEmptyText = true;

const Selection = ({ facets, getUrl, getEmptyUrl }) => {
  const processedFacets = useEditableFacets(facets);
  const { texts: [selectedHeaderLbl, removeFacetButtonLbl, deleteLbl, clearSelection], loaded } = useSanaTexts(textsToLoad);
  if (!loaded)
    return null;

  const title = makeSimpleText(selectedHeaderLbl) + ':';
  const delTitle = makeSimpleText(deleteLbl);

  return (
    <section className={styles.selections} aria-label={title}>
        <Link className={`${btnStyles.btn} ${styles.clearFilterBtn}`}{...getEmptyUrl()}>
          {makeSimpleText(clearSelection)}
        </Link>
    </section>
  );
};

Selection.propTypes = {
  facets: PropTypes.array,
  getUrl: PropTypes.func.isRequired,
  getEmptyUrl: PropTypes.func.isRequired,
};

export default memo(Selection);