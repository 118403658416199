//[156332] [Siremballage] 3.10. Custom menu - [F1]

import styles from '../MainNav.module.scss';
import { memo, useRef, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { SublistParamsPropTypes } from '../PropTypes';
import { SublistPositionContext, Positions } from '../SublistPositionProvider';
import { removeListHoverState } from '../eventHandlers';
import { useIsTouchScreen } from 'utils/detections';
import { useIsMouse } from 'components/detection';
import { hoveredItemClass } from '../eventHandlers';
import { image } from '../../../../primitives/grid/MediaBackground.module.scss';
import { RichText } from 'components/sanaText';

const CustomMenuViewSubItems = ({
  subItems,
  id,
  createItem,
  title,
  isAccordion,
  expanded,
  ariaSubmenuText,
  subListLevel,
  sublistParams,
  backgroundImg,
  menuDescription
}) => {
  const ref = useRef(null);
  const positionContext = useContext(SublistPositionContext);
  const isTouchScreen = useIsTouchScreen();
  const isMouse = useIsMouse();

  const posClass = useMemo(() => {
    if (isAccordion)
      return '';

    if (!positionContext || !sublistParams)
      return styles.leftPos;

    const position = positionContext.getSublistPosition(`${sublistParams.startOffset}_${sublistParams.direction}`, subListLevel);
    return position === Positions.LEFT ? styles.leftPos : styles.rightPos;
  }, [isAccordion, positionContext, subListLevel, sublistParams]);

  useEffect(() => {
    if (!ref.current.parentElement.classList.contains(hoveredItemClass))
      return;

    ref.current.setAttribute('aria-hidden', 'false');
    ref.current.setAttribute('aria-expanded', 'true');
  }, []);

  return (
    <>
      <div ref={ref}
        // Element should be focusable for proper event handling in Mobile Safari, Legacy MS Edge and IE11 when using laptop with touch screen.
        tabIndex="-1"
        id={id}
        className={`${styles.subItems} ${styles.focusable} ${posClass}`}
        // onMouseLeave is not reliable when using touch screen on laptop - it is fired twice for touch position and last known mouse cursor position.
        onMouseLeave={isTouchScreen && isMouse === false ? null : removeListHoverState}
        aria-label={`${title} ${ariaSubmenuText}`}
        aria-hidden={isAccordion ? !expanded : 'true'}
        aria-expanded={isAccordion ? expanded : 'false'}>
        <ul className={`${((backgroundImg && backgroundImg.path) || menuDescription) ? styles.levelTwo : styles.levelTwoFull}`}>
            {subItems.map(createItem)}
        </ul>
        {((backgroundImg && backgroundImg.path) || menuDescription) &&
          <div>
            {backgroundImg && backgroundImg.path &&
              <>
                <img src={backgroundImg.path} />
              </>
            }
            {menuDescription &&
              <span><RichText textKey="">{menuDescription}</RichText></span>
            }
          </div>
        }
      </div>
    </>   
  );
};

CustomMenuViewSubItems.propTypes = {
  subItems: PropTypes.array,
  id: PropTypes.string.isRequired,
  createItem: PropTypes.func.isRequired,
  title: PropTypes.string,
  isAccordion: PropTypes.bool,
  expanded: PropTypes.bool,
  ariaSubmenuText: PropTypes.string,
  subListLevel: PropTypes.number.isRequired,
  sublistParams: SublistParamsPropTypes,
};

export default memo(CustomMenuViewSubItems);
