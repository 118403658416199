//[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages

import styles from '../../product/Details.module.scss';
import btnStyles from '../ProductGroup.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToWishList } from 'behavior/wishList';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { useSanaButton } from 'components/primitives/buttons';
import { HeartIcon } from 'components/primitives/icons';
import { Placeholder } from 'components/primitives/placeholders';
import { useModal } from 'components/objects/modals';
import { useAbilities } from 'components/objects/user';
import ConfirmationModal from 'components/objects/wishList/confirmationModal';
import { useOnChange } from 'utils/hooks';
import { connectToContext } from 'utils/react';
import LoginPrompt from './LoginPrompt';
import SuccessMessage from './SuccessMessage';
import ProductContext from '../ProductContext';

const AddToWishlistButton = ({
    product,
    uomId,
    variantId,
    isDesignerMode,
    hideHeartIcon,
    showLoginFirstPopup,
  }) => {
  const dispatch = useDispatch();
  const wishList = useSelector(state => state.wishList);
  const { opened, hide, show } = useModal();
  const { text, loaded } = useSanaButton('AddToWishList');

  //useOnChange(() => show(), [wishList, product], false);
    
  if (!loaded)
    return <Placeholder className={styles.addToWishListPlaceholder} />;

  const addToWishList = () => {
    if (isDesignerMode)
      return;

    show();

    if (!showLoginFirstPopup)
      dispatch(addProductToWishList(product.id, uomId, variantId));
  };

  return (
    <>
      <button className={`${linkStyles.link} ${btnStyles.addToWishListBtn}`} onClick={addToWishList} type="button">
        <HeartIcon />
      </button>
      <ConfirmationModal opened={opened} hide={hide}>
        {showLoginFirstPopup ? <LoginPrompt /> : <SuccessMessage />}
      </ConfirmationModal>
    </>
  );
};

AddToWishlistButton.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    productConfiguratorInfo: PropTypes.shape({
      isProductConfigurable: PropTypes.bool,
    }),
  }).isRequired,
  uomId: PropTypes.string,
  variantId: PropTypes.string,
  isDesignerMode: PropTypes.bool,
  hideHeartIcon: PropTypes.bool,
  showLoginFirstPopup: PropTypes.bool,
};

export default connectToContext(
  [ProductContext],
  ({ product, uomId, variantId }) => ({ product, uomId, variantId }),
)(memo(props => {
  const wishListEnabled = useSelector(state => props.isDesignerMode || state.settings.wishListEnabled);
  const { 
    isLoading, 
    abilities: [canUseWishlist, orderProductsAbility], 
  } = useAbilities([AbilityTo.UseWishlist, AbilityTo.OrderProducts]);

  if (!wishListEnabled
    || isLoading
    || props.product.productConfiguratorInfo?.isProductConfigurable
    || orderProductsAbility !== AbilityState.Available
    || (!props.isDesignerMode && canUseWishlist === AbilityState.NotAvailable))
    return null;

  return (
    <AddToWishlistButton
      showLoginFirstPopup={canUseWishlist === AbilityState.Unauthorized}
      {...props}
    />
  );
}));
