//[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages

import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { navigateTo } from 'behavior/events';
import { getViewModeProductAmount } from 'behavior/pages/productList';
import { useLoadMoreUrlBuilder } from 'components/objects/productList/hooks';
import { getNextPageInfo } from 'behavior/paging';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';

const LoadMoreButton = ({ pageSize, queryStringCount, loadedProductsCount, totalCount, loadedCount, navigateTo, containerId }) => {
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
    return () => setTimeout(toggleOverflowAnchorState, 50);
  }, [loadedCount]); //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages

  const productsCount = queryStringCount || loadedCount; //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages

  const {
    immediateLoad,
    pageToLoad,
    nextPageCount,
    itemsToLoad: productsCountToLoad,
  } = getNextPageInfo(productsCount, pageSize, totalCount);

  const { url, to } = useLoadMoreUrlBuilder(nextPageCount, pageToLoad, pageSize);

  useEffect(() => {
    if (!immediateLoad)
      return;

    setLoading(true);
    navigateTo(to, url, { omitScroll: true, replaceHistory: true });
  }, [immediateLoad]);

  if (immediateLoad || productsCount >= totalCount)
    return null;

  return (
    <div className="text-right">
      <SanaButton
        className={`${isLoading ? `${btnStyles.loading} ` : ''}${btnStyles.btnLoadMore} ${btnStyles.btnBig}`}
        textKey="ShowNextNProducts"
        formatWith={[productsCountToLoad]}
        onClick={() => {
          setLoading(true);
          toggleOverflowAnchorState(true);
          navigateTo(to, url, { omitScroll: true, replaceHistory: true });
        }}
      />
    </div>
  );
};

LoadMoreButton.propTypes = {
  pageSize: PropTypes.number,
  queryStringCount: PropTypes.number,
  loadedProductsCount: PropTypes.number,
  totalCount: PropTypes.number,
  loadedCount: PropTypes.number, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
  navigateTo: PropTypes.func,
  containerId: PropTypes.string.isRequired, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
};

export default connect(({
  routing: { routeData: { params: { viewMode, count: queryStringCount } } },
  settings: { productList },
  page: { products, productsSf, selectedViewMode, defaultViewMode },
  containerId
}) => ({
  pageSize: getViewModeProductAmount('List', productList),
  queryStringCount,
  loadedProductsCount: containerId === 'normal' ? products && products.length : productsSf && productsSf.length, //[156335] [SF] [Siremballage] 3.14. Level 3 product group pages
}), { navigateTo })(LoadMoreButton);
