//[156335] [FACET] [Siremballage] 3.14. Level 3 product group pages
import styles from './Facets.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import Facet from './Facet';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import useEditableFacets from './useEditableFacets';
import { Row, Col } from 'components/primitives/grid';

const FacetsList = ({ facets, isMobile, getUrl, multiSelect, getAllUrl }) => {
  const filteredFacets = facets.filter(f => !f.anySelected || multiSelect);
  const processedFacets = useEditableFacets(filteredFacets);

  let [title] = useSanaTexts(['Filters'], makeSimpleText).texts;
  if (title)
    title += ':';

  return (
    <section aria-label={title}>
      <h2 id="filtersHeader" className="visually-hidden">{title}</h2>
      <Row>
        {processedFacets.map(facet =>
          <>
            <Col md="3" xs="12" className={styles.dropDownColumn}>
              <Facet facet={facet} key={facet.name} isMobile={isMobile} getUrl={getUrl} getAllUrl={getAllUrl} multiSelect={multiSelect} />
            </Col>
          </>
        )
        }
      </Row>
    </section>
  );
};

FacetsList.propTypes = {
  facets: PropTypes.arrayOf(
    PropTypes.shape({
      anySelected: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  isMobile: PropTypes.bool,
  getUrl: PropTypes.func.isRequired,
  getAllUrl: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool.isRequired,
};

export default memo(FacetsList);
