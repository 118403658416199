//[156335] [PAGING] [Siremballage] 3.14. Level 3 product group pages

import { createContext } from 'react';

const ListContext = createContext({
  quantities: null,
  updateQuantity: (productId, uomId, quantity) => null,
  updateVariantQuantities: (productId, uomID, variantQuantityMap) => null,
});

export default ListContext;